$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

$primary: #3682d9;
$secondary-color: #a9d8df;
$secondary: #fcfcfc;

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: #000;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  opacity: 0.95;
}

.h3-top-margin {
  margin-top: 1em;
}

.center-content {
  display: flex;
  justify-content: center;
}

.slight-left-negative-margin {
  margin-left: -4px;
}

.slight-right-negative-margin {
  margin-right: -4px;
}

p {
  opacity: 0.8;
}

@import "~bootstrap/scss/bootstrap.scss";

.bg-landing {
  background: url("./assets/svg/landing-small.svg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}

.bg-product {
  background: url("./assets/svg/values-small.svg") no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position-y: 150px;
}

.body {
  scroll-behavior: smooth;
}

.bg-values {
  background-size: cover;
  background: $secondary url("./assets/svg/values-small.svg");
  width: 100%;
  height: 100%;
}

.bg-education {
  background: url("./assets/svg/bg-education-small.svg");
  background-size: cover;

  width: 100%;
  height: 600px;
}

.feature-1-danish {
  background-color: $secondary;
  background-image: url("./assets/img/feature-create-team-danish.png");
  background-size: 100%;
  background-position-y: 250px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-1-english {
  background-color: $secondary;
  background-image: url("./assets/img/feature-create-team-english.png");
  background-size: 100%;
  background-position-y: 250px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-2-english {
  background-color: $secondary;
  background-image: url("./assets/svg/feature-send-answer-waves-english.svg");
  background-size: 100%;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-2-danish {
  background-color: $secondary;
  background-image: url("./assets/img/feature-send-answer-waves-danish.png");
  background-size: 100%;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-3-english {
  background-color: $secondary;
  background-image: url("./assets/img/feature-reach-out-english.png");
  background-size: 80%;
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-3-danish {
  background-color: $secondary;
  background-image: url("./assets/img/feature-reach-out-danish.png");
  background-size: 80%;
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-4-english {
  background-color: $secondary;
  background-image: url("./assets/img/feature-insight-english.png");
  background-size: 80%;
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.feature-4-danish {
  background-color: $secondary;
  background-image: url("./assets/img/feature-insight-danish.png");
  background-size: 80%;
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-radius: 10px;
}

.bg-vision-mission {
  background: url("./assets/svg/bg-vision-mission-small.svg");
  background-size: cover;
  width: 100%;
  height: 660px;
}
.bg-premium {
  background: url("./assets/svg/premium-small.svg");
  background-size: cover;
  width: 100%;
}

.text-black-white {
  color: #000;
}

@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {
  .inactive-nav-link-margin {
    color: #000000;
  }

  .bg-landing {
    background: url("./assets/svg/landing-medium.svg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 342px;
  }
  .bg-values {
    background: $secondary none;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .bg-vision-mission {
    background: url("./assets/svg/bg-vision-mision-medium.svg");
    background-size: cover;
    width: 100%;
    height: 480px;
  }

  .bg-education {
    background: url("./assets/svg/bg-education-medium.svg");
    background-size: cover;

    width: 100%;
    height: 650px;
  }
  .bg-premium {
    background: url("./assets/svg/premium-medium.svg");
    background-size: cover;
    width: 100%;
  }
}
@include media-breakpoint-up(lg) {
  .inactive-nav-link-margin {
    color: #000000;
    margin-left: 10px;
  }

  .bg-landing {
    background: url("./assets/svg/landing-big.svg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 660px;
  }

  .text-black-white {
    color: #fff;
  }

  .bg-vision-mission {
    background: url("./assets/svg/bg-vision-mision-large.svg");
    background-size: cover;
    width: 100%;
    height: 510px;
  }

  .bg-education {
    background: url("./assets/svg/bg-education-large.svg");
    background-size: cover;
    width: 100%;
    height: 600px;
  }
  .bg-premium {
    background: url("./assets/svg/premium-big.svg");
    background-size: cover;
    width: 100%;
  }

  .bg-product {
    background: url("./assets/svg/product-big.svg") no-repeat;
    background-size: cover;
    height: 650px;
    width: 100%;
  }

  .bg-support {
    background: url("./assets/svg/question.svg") no-repeat;
    background-size: 40%;
    background-position-x: 1050px;
    background-position-y: -50px;
    width: 100%;
    height: 100%;
  }
}
@include media-breakpoint-up(xl) {
  .inactive-nav-link-margin {
    color: #000000;
    margin-left: 10px;
  }

  .bg-landing {
    background: url("./assets/svg/landing-big.svg") center no-repeat;
    background-size: cover;
    background-position-y: -100px;
    width: 100%;
    height: 800px;
  }

  .bg-vision-mission {
    background: url("./assets/svg/bg-vision-mision-large.svg");
    background-size: cover;
    width: 100%;
    height: 640px;
  }

  .bg-product {
    background: url("./assets/svg/product-big.svg") no-repeat;
    background-size: cover;
    height: 720px;
    width: 100%;
  }

  .bg-support {
    background: url("./assets/svg/question.svg") no-repeat;
    background-size: 40%;
    background-position-x: 1250px;
    background-position-y: 450px;
    width: 100%;
    height: 100%;
  }
}

.un:hover {
  opacity: 0.6;
  transition: 0.3s ease-in-out;
}

::-webkit-scrollbar {
  width: 12px;

  background: #ccc;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  height: 30px;
  border-radius: 100px;
  background: #fff;
  border: #3682d9 solid 1.5px;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

.special-btn {
  width: 276px;
}

.inactive-nav-link {
  color: #000;
  opacity: 0.6;
}

.inactive-nav-link:hover {
  color: $primary;
  opacity: 1;
  text-decoration: none;
}

.log-in-text {
  color: #000000;
  opacity: 0.6;
}

.active-nav-link {
  color: $primary;
  opacity: 1;
}

.d-shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  transform: scale(1);
  transition-duration: 400ms;
}

.d-shadow:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  transform: scale(1.05);
}

.nav-btn {
  position: relative;
}

.nav-btn::before,
.nav-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.25rem;
}

// .nav-btn::before {
//   background-color: $secondary-color;
//   z-index: -1;
//   animation: pulse 1250ms infinite;
// }

.nav-btn::after {
  background-color: $primary;
  z-index: -10;
  animation: pulse 1250ms;
  animation-iteration-count: 10;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    height: 100%;
    opacity: 0.75;
  }

  100% {
    transform: scale(1.15, 1.45);

    opacity: 0;
  }
}
